import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { POST_USER_REGISTER } from "../Constant/constant";
const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    gender: "",
    password: "",
    cpassword: "",
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    lowerCase: false,
    upperCase: false,
    number: false,
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });

    if (name === "password") {
      const length = value.length >= 8;
      const lowerCase = /[a-z]/.test(value);
      const upperCase = /[A-Z]/.test(value);
      const number = /[0-9]/.test(value);

      setPasswordValidation({
        length,
        lowerCase,
        upperCase,
        number,
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;

    if (!formData.name.trim()) newErrors.name = "Full name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid";
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.password) newErrors.password = "Password is required";
    else if (!passwordRegex.test(formData.password)) {
      newErrors.password =
        "Password must be at least 8 characters, including one uppercase letter, one lowercase letter, one number, and one special character";
    }
    if (formData.password !== formData.cpassword)
      newErrors.cpassword = "Passwords don't match";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      const { name, email, gender, password } = formData;
      const res = await axios.post(POST_USER_REGISTER, {
        name,
        email,
        gender,
        password,
      });

      toast.success("Signup successful!");

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error("User already exists. Please go to login.");
      } else {
        toast.error(error.response?.data?.message || "Registration error");
      }
    }
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };

  return (
    <>
      <main className="flex items-center justify-center min-h-screen bg-gray-100 p-6 pt-11">
        <div className="flex flex-col sm:flex-row bg-white shadow-lg rounded-md overflow-hidden w-full max-w-4xl">
          <div className="flex flex-col items-center justify-center bg-gray-600 text-white p-8 w-full sm:w-1/3">
            <h2 className="text-2xl font-semibold mb-4 text-center">
              Looks like you're new here!
            </h2>
            <p className="mb-6 text-center">
              Sign up with your full name & email to get started
            </p>
            <img
              src="https://www.tailwindtap.com/assets/components/form/userlogin/login_tailwindtap.jpg"
              alt="Signup Illustration"
              className="w-full h-32"
            />
          </div>
          <div className="p-8 w-full sm:w-2/3">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-4 items-start">
                <TextField
                  fullWidth
                  id="full-name"
                  label="Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mb-4"
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mb-4"
                  error={!!errors.email}
                  helperText={errors.email}
                />
                <div className="flex flex-col sm:flex-row gap-4 items-center mb-4 w-full">
                  <h2 className="text-md">Your Gender :</h2>
                  <RadioGroup
                    row
                    aria-labelledby="radio-buttons-group-label"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="flex-wrap"
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio />}
                      label="Female"
                    />
                  </RadioGroup>
                </div>
                {errors.gender && (
                  <p className="text-red-500 text-sm">{errors.gender}</p>
                )}
                <div className="flex flex-col sm:flex-row gap-3 items-center w-full mb-4">
                  <div className="relative w-full sm:w-1/2">
                    <TextField
                      fullWidth
                      id="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="w-full"
                      error={!!errors.password}
                      helperText={errors.password}
                      onFocus={() => setPasswordFocused(true)}
                      onBlur={() => setPasswordFocused(false)}
                    />
                    <span
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? (
                        <AiFillEyeInvisible className="text-gray-500" />
                      ) : (
                        <AiFillEye className="text-gray-500" />
                      )}
                    </span>
                    {passwordFocused && (
                      <div className="absolute top-full left-0 mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-10">
                        <h3 className="font-semibold text-gray-900">
                          Password must have at least 8 characters
                        </h3>
                        <ul className="mt-2 text-sm text-gray-500">
                          <li
                            className={`flex items-center mb-1 ${
                              passwordValidation.lowerCase
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            <svg
                              className="w-3.5 h-3.5 mr-2"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 16 12"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 5.917L5.724 10.5 15 1.5"
                              />
                            </svg>
                            Lower case letter
                          </li>
                          <li
                            className={`flex items-center mb-1 ${
                              passwordValidation.upperCase
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            <svg
                              className="w-3.5 h-3.5 mr-2"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 16 12"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 5.917L5.724 10.5 15 1.5"
                              />
                            </svg>
                            Upper case letter
                          </li>
                          <li
                            className={`flex items-center mb-1 ${
                              passwordValidation.number
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            <svg
                              className="w-3.5 h-3.5 mr-2"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 16 12"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 5.917L5.724 10.5 15 1.5"
                              />
                            </svg>
                            A number
                          </li>
                          <li
                            className={`flex items-center mb-1 ${
                              passwordValidation.length
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            <svg
                              className="w-3.5 h-3.5 mr-2"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 16 12"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 5.917L5.724 10.5 15 1.5"
                              />
                            </svg>
                            At least 8 characters
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="relative w-full sm:w-1/2">
                    <TextField
                      fullWidth
                      id="confirm-password"
                      label="Confirm Password"
                      type={showCPassword ? "text" : "password"}
                      name="cpassword"
                      value={formData.cpassword}
                      onChange={handleChange}
                      className="w-full"
                      error={!!errors.cpassword}
                      helperText={errors.cpassword}
                    />
                    <span
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={toggleShowCPassword}
                    >
                      {showCPassword ? (
                        <AiFillEyeInvisible className="text-gray-500" />
                      ) : (
                        <AiFillEye className="text-gray-500" />
                      )}
                    </span>
                  </div>
                </div>

                <button
                  type="submit"
                  className="text-white py-3 w-full bg-gray-600 shadow hover:shadow-lg rounded-sm font-medium"
                >
                  Sign Up
                </button>
                <Link
                  to="/login"
                  className="hover:bg-gray-50 text-blue-500 text-center py-3 w-full shadow border rounded-sm font-medium mt-4"
                >
                  Existing User? Log in
                </Link>
              </div>
            </form>
          </div>
        </div>
      </main>
      <ToastContainer />
    </>
  );
};
export default Signup;
