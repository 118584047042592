






import React from 'react';

const CategoryTransactionList = () => {
  return (
    <>
      <div className="w-full">
        <div className="marquee-container bg-blue-900 text-white p-2 shadow-md">
          <p className="marquee-text">
            🚀 <strong>Coming Soon:</strong> New Features and Updates are on the way!
          </p>
        </div>
      </div>
      <div className="p-4 bg-[#090D16] min-h-screen flex items-start justify-center">
        {/* Transaction List */}
        <div className="rounded-lg shadow-md p-6 w-2/3 mr-4 bg-[#162232]">
          <div className="flex justify-between items-center pb-4">
            <h2 className="text-xl font-semibold text-white">Transaction List <span className="blink">(Coming Soon)</span></h2>
            <button className="bg-green-100 text-green-600 px-4 py-2 rounded-lg hover:bg-green-200">
              Manage All Orders →
            </button>
          </div>
          <table className="w-full">
            <thead>
              <tr className="text-left text-white">
                <th className="pb-2">Order ID</th>
                <th className="pb-2">Customer</th>
                <th className="pb-2">Product</th>
                <th className="pb-2">Date</th>
                <th className="pb-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {[
                { id: '#A12367J', customer: 'Katarina', product: 'Guide Hat', date: '18/03/24, 12:40', status: 'Completed', statusColor: 'bg-green-100 text-green-600' },
                { id: '#A12367J', customer: 'Rika', product: 'Sash', date: '18/03/24, 12:40', status: 'Completed', statusColor: 'bg-green-100 text-green-600' },
                { id: '#A12367J', customer: 'Sandy', product: 'Cab Badge', date: '18/03/24, 12:40', status: 'Need Pay', statusColor: 'bg-yellow-100 text-yellow-600' },
                { id: '#A12367J', customer: 'Norman', product: 'Pocket Emblem', date: '18/03/24, 12:40', status: 'Need Pay', statusColor: 'bg-yellow-100 text-yellow-600' },
                { id: '#A12367J', customer: 'Ayush', product: 'Flag pole top', date: '18/03/24, 12:40', status: 'Need Pay', statusColor: 'bg-yellow-100 text-yellow-600' },
              ].map((item, index) => (
                <tr key={index} className="border-t">
                  <td className="py-2 text-white">{item.id}</td>
                  <td className="py-2 text-white">{item.customer}</td>
                  <td className="py-2 text-white">{item.product}</td>
                  <td className="py-2 text-white">{item.date}</td>
                  <td className="py-2">
                    <span className={`px-3 py-1 rounded-lg ${item.statusColor}`}>
                      {item.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Top Selling Products */}
        <div className="rounded-lg shadow-md p-6 w-1/3 bg-[#162232]">
          <h2 className="text-xl font-semibold pb-4 text-white">Top Selling Products <span className="blink">(Coming Soon)</span></h2>
          {[
            { product: 'Guide Hat', price: '₹80', image: "https://res.cloudinary.com/shivammishra/image/upload/s--xwc8PES7--/v1722668837/gszium5vqb9doemxj9ux.jpg", status: 'In Stock', unitsSold: 3000, statusColor: 'bg-green-100 text-green-600' },
            { product: 'Sash', price: '₹56', image: "https://res.cloudinary.com/shivammishra/image/upload/s--q7MOR_ql--/v1722669206/ekmqxatzafilo2dbdhtf.jpg", status: 'Empty', unitsSold: 2000, statusColor: 'bg-red-100 text-red-600' },
            { product: 'Cab Badge', price: '₹120', image: "https://res.cloudinary.com/shivammishra/image/upload/s--PU8c1xtb--/v1722669805/kvqahder7ckvvrkmceam.jpg", status: 'In Stock', unitsSold: 800, statusColor: 'bg-green-100 text-green-600' },
          ].map((item, index) => (
            <div key={index} className="flex justify-between items-center py-2 border-t">
              <div className="flex items-center">
                <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center mr-4">
                  <img src={item.image} alt={item.product} className="w-8 h-8" />
                </div>
                <div>
                  <p className="font-semibold text-white">{item.product}</p>
                  <p className="text-gray-500">{item.price} each unit</p>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <span className={`px-3 py-1 rounded-lg ${item.statusColor}`}>{item.status}</span>
                <p className="text-gray-500">Sold {item.unitsSold} units</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <style jsx>{`
        .marquee-container {
          overflow: hidden;
          white-space: nowrap;
          position: relative;
        }

        .marquee-text {
          display: inline-block;
          padding-left: 100%;
          animation: marquee 15s linear infinite;
        }

        @keyframes marquee {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(-100%);
          }
        }

        .blink {
          animation: blink 1.5s step-start infinite;
        }

        @keyframes blink {
          0% { opacity: 1; }
          25% { opacity: 0; }
          50% { opacity: 1; }
          75% { opacity: 0; }
          100% { opacity: 1; }
        }
      `}</style>
    </>
  );
};

export default CategoryTransactionList;