
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AiFillDelete } from "react-icons/ai";
import swal from 'sweetalert';
import Header from "./Header";
import { GET_CATEGORIES, POST_CATEGORIES } from "./Constant/constant";
const AddMultipleProduct= () => {
  const [product, setProduct] = useState({
    name: "",
    description: "",
    gender: "",
    basePrice: "",
    discount: "",
    discountType: "",
    category: "",
    weight:"",
    images: [],
  });

  const [categories, setCategories] = useState([]);
  const [sizesAndStocks, setSizesAndStocks] = useState([{ size: "", stock: "" }]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(GET_CATEGORIES);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Error fetching categories");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSizeStockChange = (index, field, value) => {
    const updatedSizesAndStocks = sizesAndStocks.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setSizesAndStocks(updatedSizesAndStocks);
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setProduct({ ...product, images: files });
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setProduct({ ...product, category: value });
  };

  const handleAddMore = () => {
    setSizesAndStocks([...sizesAndStocks, { size: "", stock: "" }]);
  };

  const handleDelete = (index) => {
    const updatedSizesAndStocks = [...sizesAndStocks];
    updatedSizesAndStocks.splice(index, 1);
    setSizesAndStocks(updatedSizesAndStocks);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", product.name);
      formData.append("description", product.description);
      formData.append("gender", product.gender);
      formData.append("basePricing", product.basePrice);
      formData.append("discount", product.discount);
      formData.append("discountType", product.discountType);
      formData.append("category", product.category);
      formData.append("weight", product.weight);
      sizesAndStocks.forEach(({ size, stock }) => {
        formData.append("sizes", size);
        formData.append("stocks", stock);
      });
      product.images.forEach((image) => {
        formData.append("image", image);
      });
     console.log("FormData to be send in the Api",formData);
      const response = await axios.post(
        POST_CATEGORIES,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Product added:", response.data);
      toast.success("Product added successfully");
      setProduct({
        name: "",
        description: "",
        gender: "",
        basePrice: "",
        discount: "",
        discountType: "",
        category: "",
        weight:"",
        images: [],
      });
      setSizesAndStocks([{ size: "", stock: "" }]);
      setLoading(false);
      swal("Success!", "Product added successfully!", "success");
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product");
      setLoading(false); // Set loading state to false if there's an error
      swal("Error!", "Failed to add product!", "error");
    }
  };

  const canAddMore = sizesAndStocks.every(({ size, stock }) => size !== "" && stock !== "");

  return (
    <div className="ml-72 bg-[#090D16]">
      <Header/>
    <div className="max-w-4xl mx-auto p-8 bg-[#090D16] rounded-md">
    <h2 className="text-2xl font-bold mb-6 text-center text-white">Add Grouped Product</h2>
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-300">
            Product Name
          </label>
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={handleInputChange}
            className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-300">
            Description
          </label>
          <textarea
            name="description"
            value={product.description}
            onChange={handleInputChange}
            className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
          ></textarea>
        </div>
        
        {sizesAndStocks.map((item, index) => (
          <div className="flex space-x-4" key={index}>
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-300">
                Size
              </label>
              <input
                type="text"
                name="size"
                value={item.size}
                onChange={(e) => handleSizeStockChange(index, "size", e.target.value)}
                className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-300">
                Stock
              </label>
              <input
                type="text"
                name="stock"
                value={item.stock}
                onChange={(e) => handleSizeStockChange(index, "stock", e.target.value)}
                className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
              />
            </div>
            <button
              type="button"
              onClick={() => handleDelete(index)}
            >
              <AiFillDelete className="mt-3 text-red-600 text-2xl" />
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddMore}
          disabled={!canAddMore}
          className={`bg-blue-500 text-white px-4 py-2 rounded-md ${!canAddMore && 'opacity-50 cursor-not-allowed'} hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
        >
          Add More
        </button>
        {!canAddMore && (
          <p className="text-red-500 text-sm mt-2">Please select a size and stock for each entry.</p>
        )}
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-300">
              Base Pricing
            </label>
            <input
              type="text"
              name="basePrice"
              value={product.basePrice}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
            />
          </div>
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-300">
              Gender
            </label>
            <div className="flex space-x-2">
              {["Men", "Women", "Both"].map((gender) => (
                <button
                  key={gender}
                  type="button"
                  onClick={() => setProduct({ ...product, gender })}
                  className={`px-4 py-2 border rounded-md ${
                    product.gender === gender
                      ? "bg-green-500 text-white"
                      : "bg-gray-200"
                  } focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent`}
                >
                  {gender}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-300">
              Discount
            </label>
            <input
              type="text"
              name="discount"
              value={product.discount}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
            />
          </div>
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-300">
              Discount Type
            </label>
            <input
              type="text"
              name="discountType"
              value={product.discountType}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-300">
            Product Category
          </label>
          <select
            name="category"
            value={product.category}
            onChange={handleCategoryChange}
            className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category._id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-300">
            Upload Images
          </label>
          <input
            type="file"
            multiple
            onChange={handleImageUpload}
            className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
          />
        </div>
        <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-300">
                Weight
              </label>
              <input
                type="text"
                name="weight"
                value={product.weight}
                onChange={handleInputChange}
                className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
              />
            </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleSubmit}
            className="bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            disabled={loading}
          >
            {loading ? 'Adding...' : 'Add Product'}
          </button>
          
        </div>
     
      </div>
    </div>
    </div>
  );
};

export default AddMultipleProduct;