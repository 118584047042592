import React, { useState } from "react";
import {
  FaTachometerAlt,
  FaShoppingCart,
  FaBox,
  FaUsers,
  FaMoneyBill,
} from "react-icons/fa";
import { BiSolidCategory } from "react-icons/bi";

import { IoMdArrowDropdown } from "react-icons/io";
import { Link, NavLink } from "react-router-dom";
import { SiShutterstock } from "react-icons/si";
import image from "../img/logo3.png";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-[#1A2738] h-screen w-72 fixed shadow-lg flex flex-col justify-between">
      <div>
        <div className="flex items-center mt-6 px-6 py-4">
        <img src={image} alt="Logo" className="h-24 w-20 " />
          <div className="ml-4">
            <div className="text-2xl font-bold text-[#49F088] leading-tight">
              BSGShop
            </div>
            <div className="text-lg font-semibold text-[#9DC33A]">
              E-Commerce
            </div>
          </div>
        </div>

        <nav className="mt-8">
          <div className="text-xs font-semibold text-[#9DC33A] pl-8 mb-3 tracking-wider">
            OVERVIEW
          </div>
          <Link
            to="/dashboard"
            className="flex items-center text-white py-3 pl-8 pr-4 hover:bg-[#2A3D56] rounded-md transition-all duration-300 shadow-md"
          >
            <FaTachometerAlt className="text-lg" />
            <span className="ml-4 text-base font-medium">Dashboard</span>
          </Link>

          <Link
            className="flex items-center text-white py-3 pl-8 pr-4 mt-2 hover:bg-[#2A3D56] rounded-md transition-all duration-300 shadow-md"
            onClick={() => setIsOpen(!isOpen)}
          >
            <FaBox className="text-lg" />
            <span className="ml-4 text-base font-medium">Product</span>
            <IoMdArrowDropdown
              className={`text-lg ml-auto transition-transform duration-300 ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          </Link>

          {isOpen && (
            <div className="ml-10 mt-2 space-y-2">
              <NavLink
                to="/products"
                className="block px-4 py-2 text-sm hover:bg-[#2A3D56] rounded-md text-white transition-all duration-300"
              >
                All Products
              </NavLink>
              <NavLink
                to="/add-product"
                className="block px-4 py-2 text-sm hover:bg-[#2A3D56] rounded-md text-white transition-all duration-300"
              >
                Add Simple Product
              </NavLink>
              <NavLink
                to="/grouped-product"
                className="block px-4 py-2 text-sm hover:bg-[#2A3D56] rounded-md text-white transition-all duration-300"
              >
                Add Grouped Product
              </NavLink>
              <NavLink
                to="/variable-product"
                className="block px-4 py-2 text-sm hover:bg-[#2A3D56] rounded-md text-white transition-all duration-300"
              >
                Add Variable Product
              </NavLink>
            </div>
          )}
          <Link
            to="/add-category"
            className="flex items-center text-white py-3 pl-8 pr-4 mt-2 hover:bg-[#2A3D56] rounded-md transition-all duration-300 shadow-md"
          >
            < BiSolidCategory className="text-lg" />
            <span className="ml-4 text-base font-medium">Category</span>
          </Link>
          
          <Link
            to="/stock"
            className="flex items-center text-white py-3 pl-8 pr-4 mt-2 hover:bg-[#2A3D56] rounded-md transition-all duration-300 shadow-md"
          >
            <SiShutterstock  className="text-lg" />
            <span className="ml-4 text-base font-medium">Stock</span>
          </Link>

          <Link
            to="/orders"
            className="flex items-center text-white py-3 pl-8 pr-4 mt-4 hover:bg-[#2A3D56] rounded-md transition-all duration-300 shadow-md"
          >
            <FaShoppingCart className="text-lg" />
            <span className="ml-4 text-base font-medium">Orders</span>
          </Link>
          <Link
            to="/customers"
            className="flex items-center text-white py-3 pl-8 pr-4 mt-2 hover:bg-[#2A3D56] rounded-md transition-all duration-300 shadow-md"
          >
            <FaUsers className="text-lg" />
            <span className="ml-4 text-base font-medium">Customers</span>
          </Link>
          <Link
            to="/carousel"
            className="flex items-center text-white py-3 pl-8 pr-4 mt-2 hover:bg-[#2A3D56] rounded-md transition-all duration-300 shadow-md"
          >
            <FaUsers className="text-lg" />
            <span className="ml-4 text-base font-medium lowercase">ADD CAROUSEL</span>
          </Link>
          
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
