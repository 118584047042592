import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import Swal from "sweetalert2";
import "./CustomerList.css";
import { BASE_URL, GET_USER, POST_USER_REGISTER } from "./Constant/constant";

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [newCustomer, setNewCustomer] = useState({
    name: "",
    email: "",
    gender: "",
    password: "",
    isAdmin: false,
  });

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(GET_USER);
        setCustomers(response.data.users);
      } catch (err) {
        console.error(err.message);
        setError("Failed to fetch customers");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewCustomer((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAddCustomer = async () => {
    try {
      await axios.post(POST_USER_REGISTER, newCustomer);
      const response = await axios.get(GET_USER);
      setCustomers(response.data.users);
      setShowModal(false);
      setNewCustomer({
        name: "",
        email: "",
        gender: "",
        password: "",
        isAdmin: false,
      });
      Swal.fire({
        title: 'Success!',
        text: 'Customer added successfully.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (err) {
      console.error(err.message);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to add customer.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const handleDelete = async (customerId) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });

      if (result.isConfirmed) {
        await axios.delete(`${BASE_URL}/api/v1/customers/${customerId}`);
        const response = await axios.get(GET_USER);
        setCustomers(response.data.users);
        Swal.fire('Deleted!', 'Customer has been deleted.', 'success');
      }
    } catch (err) {
      console.error(err.message);
      Swal.fire('Error!', 'Failed to delete customer.', 'error');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <div className="ml-72">
        <Header />
      </div>
      <div className="ml-72 p-4 bg-white h-screen">
        <div className="flex items-center  mb-6 justify-between">
          <h1 className="text-3xl font-bold text-black">
            Customer Management
          </h1>
          <button
            onClick={() => setShowModal(true)}
            className="bg-blue-900 mt-6 px-4 py-2 flex rounded-lg font-medium text-white"
          >
            + Add New Customer
          </button>
        </div>

        <div className="mt-6">
          <table className="min-w-full">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Orders</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer, index) => (
                <tr key={customer._id}>
                  <td>{index + 1}</td>
                  <td>{customer.name}</td>
                  <td>{customer.email}</td>
                  <td>{customer.orderCount || 0}</td>
                  <td>
                    {customer.isAdmin ? (
                      <span className="bg-blue-900 text-white px-2 py-1 rounded-md">Admin</span>
                    ) : (
                      <span className="bg-gray-700 text-white px-2 py-1 rounded-md">User</span>
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => handleDelete(customer._id)}
                      className="bg-red-900 text-white py-2 px-4 rounded-md"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center modal-overlay bg-opacity-100  backdrop-blur ">
          <div className="bg-slate-100 p-6 rounded-lg shadow-lg w-1/3 modal-content shadow-black" >
            <h2 className="text-xl font-semibold mb-4">Add New Customer</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleAddCustomer();
              }}
            >
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={newCustomer.name}
                  onChange={handleInputChange}
                  className="mt-1 block p-1.5 w-full border border-gray-300 rounded-md shadow-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={newCustomer.email}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Gender
                </label>
                <select
                  name="gender"
                  value={newCustomer.gender}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border p-1.5 border-gray-300 rounded-md shadow-sm"
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  value={newCustomer.password}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm"
                  required
                />
              </div>
              <div className="mb-4 flex p-1.5 items-center">
                <input
                  type="checkbox"
                  name="isAdmin"
                  checked={newCustomer.isAdmin}
                  onChange={handleInputChange}
                  className="mr-2"
                />
                <label className="text-sm font-medium text-gray-700">
                  Admin
                </label>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="mr-2 px-4 py-2 bg-gray-300 text-black rounded-md"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-900 text-white rounded-md"
                >
                  Add Customer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerList;