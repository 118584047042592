
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Colors } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const SalesSummaryChart = () => {
  const data = {
    labels: ['17 Mar', '18 Mar', '19 Mar', '20 Mar', '21 Mar', '22 Mar', '23 Mar'],
    datasets: [
      {
        label: 'Current Week',
        data: [12, 19, 3, 5, 2, 3, 18],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
      {
        label: 'Week 1',
        data: [2, 3, 20, 5, 1, 4, 10],
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      },
      {
        label: 'Week 2',
        data: [3, 10, 13, 15, 22, 30, 45],
        borderColor: 'rgba(255, 206, 86, 1)',
        backgroundColor: 'rgba(255, 206, 86, 0.2)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 18, // Increase legend font size
          },
        },
      },
      title: {
        display: true,
        text: 'Sales Summary',
        font: {
          size: 28, // Increase title font size
        },
        color: 'white',
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 16, // Increase x-axis labels font size
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 16, // Increase y-axis labels font size
          },
        },
      },
    },
  };

  const chartContainerStyles = {
    width: '400px',  // Increase the width of the chart
    height: '400px', // Increase the height of the chart
    margin: '0 auto', // Center the chart horizontally
  };

  return (
    <div style={chartContainerStyles}>
      <Line data={data} options={options} />
    </div>
  );
};

export default SalesSummaryChart;
