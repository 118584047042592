// export const BASE_URL="http://localhost:5000"
// export const BASE_URL="http://203.161.41.217/api_backend"
export const BASE_URL="https://bsgshop.bsgindia.org"

export const GET_USER=BASE_URL+"/api/v1/users"

export const POST_USER_REGISTER=BASE_URL+"/api/v1/register"
export const POST_USER_lOGIN=BASE_URL+"/api/v1/login"

export const GET_CATEGORIES=BASE_URL+"/api/v1/categories"
export const POST_CATEGORIES=BASE_URL+"/api/v1/categories"

export const POST_PRODUCT=BASE_URL+"/api/v1/categories/product"
export const GET_ALL_PRODUCT=BASE_URL+"/api/v1/categories/all-products"


export const GET_ORDERS=BASE_URL+"/api/v1/orders"
export const GET_VISITORS=BASE_URL+"/api/v1/visitors"

export const GET_LOW_STOCK=BASE_URL+"/api/v1/categories/low-stock"

export const POST_DAILY_REPORT=BASE_URL+"/api/send-daily-report"

