import React, { useEffect, useState } from "react";
import axios from "axios";
import { GET_VISITORS } from "./Constant/constant";

const TotalVisitors = () => {
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [visitors, setVisitors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [visitorsPerPage] = useState(20);

  useEffect(() => {
    const fetchVisitors = async () => {
      try {
        const response = await axios.get(GET_VISITORS);
        setTotalVisitors(response.data.totalVisitor || 0);
        setVisitors(response.data.visitors || []);
      } catch (error) {
        console.error("Error fetching visitors:", error);
      }
    };

    fetchVisitors();
  }, []);

  const indexOfLastVisitor = currentPage * visitorsPerPage;
  const indexOfFirstVisitor = indexOfLastVisitor - visitorsPerPage;
  const currentVisitors = visitors.slice(indexOfFirstVisitor, indexOfLastVisitor);

  const totalPages = Math.ceil(visitors.length / visitorsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="ml-72 p-6 bg-[#090D16] text-white shadow-xl">
      <h3 className="text-lg text-blue-400 font-semibold mb-4">Total Visitors</h3>
      <p className="text-2xl font-bold text-white mb-6">{totalVisitors}</p>

      <h4 className="text-md text-blue-400 font-semibold mb-4">Visitor List</h4>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-[#0E1223] text-white border border-blue-400 rounded-lg">
          <thead>
            <tr className="text-center">
              <th className="px-4 py-3 border-b border-blue-400 uppercase">S.No.</th>
              <th className="px-4 py-3 border-b border-blue-400 uppercase">IP Address</th>
              <th className="px-4 py-3 border-b border-blue-400 uppercase">Country</th>
              <th className="px-4 py-3 border-b border-blue-400 uppercase">Url</th>
              <th className="px-4 py-3 border-b border-blue-400 uppercase">Visited At</th>
            </tr>
          </thead>
          <tbody>
            {currentVisitors.map((visitor, index) => (
              <tr
                key={visitor._id}
                className={`text-center ${index % 2 === 0 ? "bg-[#1A1F3B]" : "bg-[#0E1223]"}`}
              >
                <td className="px-4 py-3 border-b border-blue-400">
                  {indexOfFirstVisitor + index + 1}
                </td>
                <td className="px-4 py-3 border-b border-blue-400">{visitor.ip}</td>
                <td className="px-4 py-3 border-b border-blue-400">{visitor.country}</td>
                <td className="px-4 py-3 border-b border-blue-400">{visitor.triggeredUrl}</td>
                <td className="px-4 py-3 border-b border-blue-400">
                  {new Date(visitor.visitedAt).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center mt-6 space-x-2">
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className={`px-3 py-2 mx-1 border border-blue-400 rounded-md ${
            currentPage === 1
              ? "bg-gray-600 text-gray-400 cursor-not-allowed"
              : "bg-[#090D16] text-blue-400 hover:bg-blue-500 hover:text-white"
          } transition-all duration-200`}
        >
          Previous
        </button>

        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className={`px-3 py-2 mx-1 border border-blue-400 rounded-md ${
            currentPage === totalPages
              ? "bg-gray-600 text-gray-400 cursor-not-allowed"
              : "bg-[#090D16] text-blue-400 hover:bg-blue-500 hover:text-white"
          } transition-all duration-200`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TotalVisitors;
