import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { AiFillDelete } from "react-icons/ai";
import Swal from 'sweetalert2';
import Header from "./Header";
import { GET_CATEGORIES, POST_PRODUCT } from "./Constant/constant";

const AddProduct = () => {
  const [product, setProduct] = useState({
    name: "",
    description: "",
    gender: "",
    basePrice: "",
    discount: "",
    discountType: "", 
    category: "",
    weight: "",
    images: [],
    lowStockThreshold: "",
    hsnumber: "",
    gstAmount: "",
    gstPercentage: ""
  });

  const [categories, setCategories] = useState([]);
  const [sizesAndStocks, setSizesAndStocks] = useState([{ size: "", stock: "" }]);
  const [loading, setLoading] = useState(false);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("https://bsgshop.bsgindia.org/api/v1/categories");
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Error fetching categories");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSizeStockChange = (index, field, value) => {
    const updatedSizesAndStocks = sizesAndStocks.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setSizesAndStocks(updatedSizesAndStocks);
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setProduct({ ...product, images: files });
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setSelectedCategoryName(selectedCategory);
    setProduct({ ...product, category: selectedCategory });
  };

  const handleAddMore = () => {
    setSizesAndStocks([...sizesAndStocks, { size: "", stock: "" }]);
  };

  const handleDelete = (index) => {
    const updatedSizesAndStocks = [...sizesAndStocks];
    updatedSizesAndStocks.splice(index, 1);
    setSizesAndStocks(updatedSizesAndStocks);
  };
  const validateForm = () => {
    if (!product.name || !product.description || !product.basePrice || !product.category ||
        !product.weight || !product.hsnumber || !product.gstAmount || !product.gstPercentage ||
        !product.lowStockThreshold || sizesAndStocks.some(({ size, stock }) => !size || !stock)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill in all required fields!',
        customClass: {
          container: 'swal-container',
          title: 'swal-title',
          content: 'swal-content',
          confirmButton: 'swal-confirm-button'
        },
        backdrop: `rgba(255, 0, 0, 0.4)`
      });
      return false;
    }
    return true;
  };
  const handleSubmit = async () => {
    if (!validateForm()) return;
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", product.name);
      formData.append("description", product.description);
      formData.append("gender", product.gender);
      formData.append("basePricing", product.basePrice);
      formData.append("discount", product.discount);
      formData.append("discountType", product.discountType);
      formData.append("category", selectedCategoryName);
      formData.append("weight", product.weight);
      formData.append("hsnNumber", product.hsnumber);
      formData.append("gstAmount", product.gstAmount);
      formData.append("gstPercentage", product.gstPercentage);
      sizesAndStocks.forEach(({ size, stock }) => {
        formData.append("sizes", size);
        formData.append("stocks", stock);
      });
      product.images.forEach((image) => {
        formData.append("image", image);
      });
      formData.append("lowStockThreshold", product.lowStockThreshold);

      console.log("FormData to be sent in the API", formData);

      const response = await axios.post(
        POST_PRODUCT,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Product added:", response.data);
      toast.success("Product added successfully");
      setProduct({
        name: "",
        description: "",
        gender: "",
        basePrice: "",
        discount: "",
        discountType: "", // Reset
        category: "",
        weight: "",
        images: [],
        lowStockThreshold: "",
        hsnumber: "", // Reset
        gstAmount: "", // Reset
        gstPercentage: "" // Reset
      });
      setSizesAndStocks([{ size: "", stock: "" }]);
      setLoading(false);
      console.log("Mukul  ")
      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Product added successfully!',
        customClass: {
          container: 'custom-swal-container',
          title: 'custom-swal-title',
          content: 'custom-swal-content',
          confirmButton: 'custom-swal-confirm-button'
        }
      });
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product");
      setLoading(false); 
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to add product!',
        customClass: {
          container: 'custom-swal-container',
          title: 'custom-swal-title',
          content: 'custom-swal-content',
          confirmButton: 'custom-swal-confirm-button'
        }
      });
    }
  };

  const canAddMore = sizesAndStocks.every(({ size, stock }) => size !== "" && stock !== "");

  return (
    <div className="ml-72 bg-[#090D16]">
      <Header />
      <div className="max-w-4xl mx-auto p-8 bg-[#090D16] rounded-md">
        <h2 className="text-2xl font-bold mb-6 text-center text-white">Add Simple Product</h2>
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Product Name
            </label>
            <input
              type="text"
              name="name"
              value={product.name}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Description
            </label>
            <textarea
              name="description"
              value={product.description}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
            ></textarea>
          </div>

          {sizesAndStocks.map((item, index) => (
            <div className="flex space-x-4" key={index}>
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-300">
                  Size
                </label>
                <input
                  type="text"
                  name="size"
                  value={item.size}
                  onChange={(e) => handleSizeStockChange(index, "size", e.target.value)}
                  className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
                />
              </div>
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-300">
                  Stock
                </label>
                <input
                  type="text"
                  name="stock"
                  value={item.stock}
                  onChange={(e) => handleSizeStockChange(index, "stock", e.target.value)}
                  className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
                />
              </div>
              <button
                type="button"
                onClick={() => handleDelete(index)}
                className="mt-3 text-red-600 text-2xl"
              >
                <AiFillDelete />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddMore}
            
            className={`bg-blue-500 text-white px-4 py-2 rounded-md ${!canAddMore && 'opacity-50 cursor-not-allowed'} hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
          >
            Add More
          </button>
          {!canAddMore && (
            <p className="text-red-500 text-sm mt-2">Please select a size and stock for each entry.</p>
          )}
          <div className="flex space-x-4">
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-300">
                Base Pricing
              </label>
              <input
                type="text"
                name="basePrice"
                value={product.basePrice}
                onChange={handleInputChange}
                className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
              />
            </div>
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-300">
                Gender
              </label>
              <div className="flex space-x-2">
                {["Men", "Women", "Both"].map((gender) => (
                  <button
                    key={gender}
                    type="button"
                    onClick={() => setProduct({ ...product, gender })}
                    className={`px-4 py-2 border rounded-md ${
                      product.gender === gender
                        ? "bg-blue-500 text-white"
                        : "bg-gray-500 text-white"
                    }`}
                  >
                    {gender}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Discount
            </label>
            <input
              type="text"
              name="discount"
              value={product.discount}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Discount Type
            </label>
            <input
              type="text"
              name="discountType"
              value={product.discountType}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Category
            </label>
            <select
              name="category"
              value={product.category}
              onChange={handleCategoryChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Weight(in gm)
            </label>
            <input
              type="text"
              name="weight"
              value={product.weight}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              HS Number
            </label>
            <input
              type="text"
              name="hsnumber"
              value={product.hsnumber}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              GST Amount
            </label>
            <input
              type="text"
              name="gstAmount"
              value={product.gstAmount}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              GST Percentage
            </label>
            <input
              type="text"
              name="gstPercentage"
              value={product.gstPercentage}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Low Stock Threshold
            </label>
            <input
              type="text"
              name="lowStockThreshold"
              value={product.lowStockThreshold}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300">
              Images
            </label>
            <input
              type="file"
              multiple
              onChange={handleImageUpload}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100 focus:ring-black focus:border-transparent"
            />
          </div>
          <button
            type="button"
            onClick={handleSubmit}
            disabled={loading}
            className={`bg-blue-500 text-white px-4 py-2 rounded-md ${loading && 'opacity-50 cursor-not-allowed'} hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
          >
            {loading ? "Adding..." : "Add Product"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;