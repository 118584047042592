import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { RxCross1 } from "react-icons/rx";
import Header from "./Header";
import { BASE_URL, GET_ALL_PRODUCT } from "./Constant/constant";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10);
  const [viewProduct, setViewProduct] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          GET_ALL_PRODUCT 
        );
        setProducts(response.data);
        const uniqueCategories = new Set(
          response.data.map((product) => product.category)
        );
        setCategories(["All", ...uniqueCategories]);
        setLoading(false);
      } catch (error) {
        setError("Error fetching products");
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  const filteredProducts = products.filter((product) => {
    const matchesCategory =
      selectedCategory === "All" || product.category === selectedCategory;
    const matchesSearch = product.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const handleView = (product) => {
    setViewProduct(product);
  };

  

  const handleDelete = (categoryName, productId) => {
    Swal.fire({
      title: "Delete Product",
      text: `Are you sure you want to delete product?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `${BASE_URL}/api/v1/categories/${categoryName}/${productId}`
          );
         
          setProducts(products.filter((product) => product._id !== productId));
          Swal.fire("Deleted!", "Product has been deleted.", "success");
        } catch (error) {
          Swal.fire("Error!", "Failed to delete the product.", "error");
        }
      }
    });
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleProductsPerPageChange = (e) => {
    setProductsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="ml-72 bg-[#090D16]">
        <Header/>
      <h1 className="text-2xl font-semibold ml-4 text-white">Product Management</h1>
      <div className="mt-6">
        <label className="block text-sm font-medium text-white ml-4">
          Filter by Category
        </label>
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="mt-2 mb-6 p-2 border border-gray-300 rounded-md ml-4"
        >
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
        <form className="mb-3">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white ml-4"
          >
            Search
          </label>
          <div className="relative">
            <input
              type="search"
              id="default-search"
              className="block w-3/4 p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-4"
              placeholder="Search Products..."
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>
        </form>
        <div className="mb-4">
          <label className="block text-sm font-medium text-white ml-4">
            Products per Page
          </label>
          <select
            value={productsPerPage}
            onChange={handleProductsPerPageChange}
            className="mt-2 mb-6 p-2 border border-gray-300 rounded-md ml-4"
          >
            {[5, 10, 15, 20].map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </select>
        </div>
        <table className="min-w-full">
          <thead>
            <tr className="bg-[#090D16]">
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm text-white">
                ID
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm text-white">
                Name
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm text-white">
                Gender
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm text-white">
                Price
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm text-white">
                Category
              </th>
              <th className="text-left py-2 px-2 uppercase font-semibold text-xs text-white">
          Order Stock
        </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm text-white">
                Image
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm text-white">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {currentProducts.map((product, index) => (
              <tr key={product._id}>
                <td className="py-4 px-6 text-white">{indexOfFirstProduct + index + 1}</td>
                <td className="py-4 px-6 text-white">{product.name}</td>
                <td className="py-4 px-6 text-white">{product.gender}</td>
                <td className="py-4 px-6 text-white">₹{product.basePricing}</td>
                <td className="py-4 px-6 text-white">{product.category}</td>
                <td className="py-4 px-6 text-white">{product.status}</td>
                <td className="py-4 px-6 text-white">
                  <img
                    src={product.imageUrl}
                    alt={product.name}
                    className="h-12 w-12 object-cover rounded-lg"
                  />
                </td>
                <td className="py-4 px-6 flex gap-3">
                  <button
                    className="text-white font-medium bg-blue-500 px-4 py-2 rounded-md hover:bg-blue-600"
                    onClick={() => handleView(product)}
                  >
                    View
                  </button>
                  <button
                    className="text-white font-medium bg-red-700 px-4 py-2 rounded-md hover:bg-red-800"
                    onClick={() => handleDelete(product.category, product._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`px-3 py-1 mx-1 rounded  mb-2 ${
                currentPage === index + 1
                  ? "bg-[#26282a]  text-white hover:bg-blue-950"
                  : "bg-[#2a2b2e]  text-white hover:bg-blue-950"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      {viewProduct && (
        <div
          className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50 transition-opacity duration-300 ${
            viewProduct ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className={`bg-white p-8 rounded-lg shadow-2xl w-11/12 md:w-3/4 lg:w-2/3 max-w-4xl relative transform transition-transform duration-300 ${
              viewProduct ? "translate-y-0" : "translate-y-4"
            }`}
          >
            <button
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 hover:bg-gray-200 rounded-full p-2 transition ease-in-out duration-150"
              onClick={() => setViewProduct(null)}
            >
              <RxCross1 className="text-xl" />
            </button>
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-gray-900">
                Product Details
              </h2>
            </div>
            <div className="flex flex-col md:flex-row items-center md:items-start gap-8">
              <img
                src={viewProduct.imageUrl}
                alt={viewProduct.name}
                className="h-64 w-64 object-cover rounded-lg border border-gray-300 shadow-md mb-6 md:mb-0"
              />
              <div className="flex flex-col space-y-4">
                <p className="text-lg font-semibold text-gray-700">
                  <span className="font-medium text-gray-600">Name:</span>
                   {viewProduct.name}
                </p>
                <p className="text-lg font-semibold text-gray-700">
                  <span className="font-medium text-gray-600">Category:</span>
                   {viewProduct.category}
                </p>
                <p className="text-lg font-semibold text-gray-700">
                  <span className="font-medium text-gray-600">Gender:</span>
                   {viewProduct.gender}
                </p>
                <p className="text-lg font-semibold text-gray-700">
                  <span className="font-medium text-gray-600">Price:</span> ₹
                   {viewProduct.basePricing}
                </p>
                <p className="text-lg font-semibold text-gray-700">
                  <span className="font-medium text-gray-600">Discount:</span>₹
                   {viewProduct.discount}
                </p>
                <p className="text-lg font-semibold text-gray-700">
                  <span className="font-medium text-gray-600">
                    Discount Type:
                  </span>
                   {viewProduct.discountType}
                </p>
                <p className="text-lg font-semibold text-gray-700">
                  <span className="font-medium text-gray-600">Stocks:</span>
                   {viewProduct.stocks.join(", ")}
                </p>
                <p className="text-lg font-semibold text-gray-700">
                  <span className="font-medium text-gray-600">Sizes:</span>
                   {viewProduct.sizes.join(", ")}
                </p>
                <p className="text-lg font-semibold text-gray-700">
                  <span className="font-medium text-gray-600">
                    Description:
                  </span>
                   {viewProduct.description}
                </p>
              </div>
            </div>
            <div className="mt-8">
              <div className="flex flex-wrap gap-4 justify-center">
                 {viewProduct.galleryImages.map((image) => (
                  <img
                    key={image.public_id}
                    src={image.url}
                    alt="Gallery"
                    className="h-24 w-24 object-cover rounded-lg border border-gray-300 shadow-sm"
                  />
                ))}
              </div>
            </div>
          
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductList;
