










import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaPlus, FaMinus, FaEnvelope, FaEdit } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BASE_URL, GET_LOW_STOCK, POST_DAILY_REPORT } from './Constant/constant';

const Stock = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [lowStockProducts, setLowStockProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [sizes, setSizes] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [updatingStock, setUpdatingStock] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(GET_LOW_STOCK);
      setAllProducts(response.data.allProducts);
      setLowStockProducts(response.data.lowStockProducts);
    } catch (error) {
      console.error('Error fetching products:', error);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to fetch products.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSendEmail = async () => {
    setSendingEmail(true);
    try {
      const response = await axios.post(POST_DAILY_REPORT);
      if (response.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: 'Daily report has been sent!',
          icon: 'success',
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      console.error('Error sending email:', error);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to send daily report.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    } finally {
      setSendingEmail(false);
    }
  };

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setSizes(product.sizes || []);
    setStocks(product.stocks || []);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
    setSizes([]);
    setStocks([]);
  };

  const handleUpdateStock = async () => {
    if (!sizes.length || !stocks.length || sizes.length !== stocks.length) {
      Swal.fire({
        title: 'Error!',
        text: 'Please ensure sizes and stocks are provided and match in number.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    setUpdatingStock(true);
    try {
      const response = await axios.put(`${BASE_URL}/api/v1/categories/${selectedProduct.category}/${selectedProduct._id}/stocks`, {
        sizes,
        stocks
      });
      if (response.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: 'Stock has been updated!',
          icon: 'success',
          confirmButtonText: 'OK'
        });
        handleCloseModal();
        await fetchProducts();
      }
    } catch (error) {
      console.error('Error updating stock:', error);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update stock.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    } finally {
      setUpdatingStock(false);
    }
  };

  const handleSizeChange = (index, value) => {
    const newSizes = [...sizes];
    newSizes[index] = value;
    setSizes(newSizes);
  };

  const handleStockChange = (index, value) => {
    const newStocks = [...stocks];
    newStocks[index] = parseInt(value, 10);
    setStocks(newStocks);
  };

  const handleAddSizeStock = () => {
    setSizes([...sizes, '']);
    setStocks([...stocks, 0]);
  };

  const handleRemoveSizeStock = (index) => {
    setSizes(sizes.filter((_, i) => i !== index));
    setStocks(stocks.filter((_, i) => i !== index));
  };

  if (loading) return <div className="loading text-white">Loading...</div>;

  return (
    <div className='p-6 ml-72 mx-auto max-w-7xl text-white'>
      <h1 className='text-4xl font-bold mb-8'>Stock Overview</h1>

      <div className='bg-white shadow-lg rounded-lg p-6 mb-8'>
        <h2 className='text-3xl font-semibold mb-6 text-gray-700'>All Products</h2>
        <table className='min-w-full divide-y divide-gray-300'>
          <thead className='bg-gray-100'>
            <tr>
              <th className='px-4 py-3 text-left text-sm font-semibold text-white-500 uppercase tracking-wider'>S.No</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text-white-500 uppercase tracking-wider'>ID</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text-white-500 uppercase tracking-wider'>Name</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text-white-500 uppercase tracking-wider'>Category</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text-white-500 uppercase tracking-wider'>Base Pricing</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text-white-500 uppercase tracking-wider'>Total Stock</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text-white-500 uppercase tracking-wider'>Image</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text-white-500 uppercase tracking-wider'>Action</th>
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-300'>
            {allProducts.map((product, index) => (
              <tr key={product._id}>
                <td className='px-4 py-4 text-sm text-white-700'>{index + 1}</td>
                <td className='px-4 py-4 text-sm text-white-700'>{product._id}</td>
                <td className='px-4 py-4 text-sm text-white-700'>{product.name}</td>
                <td className='px-4 py-4 text-sm text-white-700'>{product.category}</td>
                <td className='px-4 py-4 text-sm text-white-700'>{product.basePricing}</td>
                <td className='px-4 py-4 text-sm text-wwhite-700'>{product.TotalStock}</td>
                <td className='px-4 py-4'>
                  <img src={product.imageUrl} alt={product.name} className='w-12 h-12 object-cover rounded-lg border border-gray-300' />
                </td>
                <td className='px-4 py-4'>
                  <button 
                    onClick={() => handleOpenModal(product)} 
                    className='bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
                  >
                    <FaEdit className='text-lg' />
                    <span>Update Stock</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className='bg-white shadow-lg rounded-lg p-6 mb-8'>
        <h2 className='text-3xl font-semibold mb-6 text-gray-700'>Low Stock Products</h2>
        <table className='min-w-full divide-y divide-gray-300'>
          <thead className='bg-red-100'>
            <tr>
              <th className='px-4 py-3 text-left text-sm font-semibold text--500 uppercase tracking-wider'>S.No</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text--500 uppercase tracking-wider'>ID</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text--500 uppercase tracking-wider'>Name</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text--500 uppercase tracking-wider'>Category</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text--500 uppercase tracking-wider'>Base Pricing</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text--500 uppercase tracking-wider'>Total Stock</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text--500 uppercase tracking-wider'>Image</th>
              <th className='px-4 py-3 text-left text-sm font-semibold text--500 uppercase tracking-wider'>Action</th>
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-300'>
            {lowStockProducts.map((product, index) => (
              <tr key={product._id}>
                <td className='px-4 py-4 text-sm text-white-700'>{index + 1}</td>
                <td className='px-4 py-4 text-sm text-white-700'>{product._id}</td>
                <td className='px-4 py-4 text-sm text-white-700'>{product.name}</td>
                <td className='px-4 py-4 text-sm text-white-700'>{product.category}</td>
                <td className='px-4 py-4 text-sm text-white-700'>{product.basePricing}</td>
                <td className='px-4 py-4 text-sm text-w-700'>{product.TotalStock}</td>
                <td className='px-4 py-4'>
                  <img src={product.imageUrl} alt={product.name} className='w-12 h-12 object-cover rounded-lg border border-gray-300' />
                </td>
                <td className='px-4 py-4'>
                  <button 
                    onClick={() => handleOpenModal(product)} 
                    className='bg-red-600 text-white px-4 py-2 rounded-lg flex items-center space-x-2 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
                  >
                    <FaEdit className='text-lg' />
                    <span>Update Stock</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && selectedProduct && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 '>
          <div className='bg-white rounded-lg shadow-lg p-8 '>
            <h3 className='text-2xl font-bold mb-4 text-gray-700'>Update Stock for {selectedProduct.name}</h3>
            <div className='mb-4'>
              {sizes.map((size, index) => (
                <div key={index} className='flex items-center mb-2'>
                  <input
                    type='text'
                    value={size}
                    onChange={(e) => handleSizeChange(index, e.target.value)}
                    className='border border-gray-300 rounded-lg px-4 py-2 mr-2 text-black'
                    placeholder='Size'
                  />
                  <input
                    type='number'
                    value={stocks[index] || ''}
                    onChange={(e) => handleStockChange(index, e.target.value)}
                    className='border border-gray-300 rounded-lg px-4 py-2 mr-2 text-black'
                    placeholder='Stock'
                  />
                  <button
                    onClick={() => handleRemoveSizeStock(index)}
                    className='bg-red-600 text-white px-3 py-1 rounded-lg flex items-center hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                  >
                    <FaMinus className='text-lg' />
                  </button>
                </div>
              ))}
              <button
                onClick={handleAddSizeStock}
                className='bg-green-600 text-white px-4 py-2 rounded-lg flex items-center hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50'
              >
                <FaPlus className='text-lg' />
                <span>Add Size & Stock</span>
              </button>
            </div>
            <div className='flex justify-end'>
              <button
                onClick={handleCloseModal}
                className='bg-gray-600 text-white px-4 py-2 rounded-lg mr-4 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50'
              >
                Close
              </button>
              <button
                onClick={handleUpdateStock}
                disabled={updatingStock}
                className='bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
              >
                {updatingStock ? 'Updating...' : 'Update Stock'}
              </button>
            </div>
          </div>
        </div>
      )}

      <div className='mt-8'>
        <button 
          onClick={handleSendEmail} 
          disabled={sendingEmail} 
          className='bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
        >
          {sendingEmail ? 'Sending Email...' : 'Send Daily Report'}
          <FaEnvelope className='inline ml-2' />
        </button>
      </div>
    </div>
  );
};

export default Stock;