import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdClose } from "react-icons/md";
import Header from "./Header";
import { BASE_URL, GET_CATEGORIES, GET_ORDERS } from "./Constant/constant";

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(10);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectOrder, setSelectOrder] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showTrackerModal, setShowTrackerModal] = useState(false);
  const [trackerId, setTrackerId] = useState("");
  const [speedpostVia, setSpeedpostVia] = useState("");
  const [invoiceFile, setInvoiceFile] = useState(null);

  // Fetch orders and categories when the component mounts or state changes
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          GET_ORDERS,
          {
            params: {
              page: currentPage,
              limit: ordersPerPage,
            },
          }
        );
        console.log(response, "response");
        setOrders(response.data.orders || []);
        setTotalOrders(response.data.totalOrders || 0);
        setTotalPages(response.data.totalPages || 0);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          GET_CATEGORIES
        );
        setCategories(response.data || []);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchOrders();
    fetchCategories();
  }, [currentPage, ordersPerPage]);

  // Handle category filter change
  const handleCategoryChange = (e) => {
    setCategoryFilter(e.target.value);
  };

  // Handle updating order status
  const handleStatusChange = async (orderId, newStatus) => {
    setLoading(true);
    try {
      if (newStatus === "shipped") {
        setSelectedOrder(orders.find((order) => order._id === orderId));
        setShowTrackerModal(true);
      } else {
        await axios.put(`${BASE_URL}/api/v1/order/${orderId}`, {
          status: newStatus,
        });
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order._id === orderId ? { ...order, status: newStatus } : order
          )
        );
        toast.success("Order status updated successfully");
      }
    } catch (error) {
      console.error("Error updating order status:", error);
      toast.error("Error updating order status");
    } finally {
      setLoading(false);
    }
  };

  const handleViewOrder = (order) => {
    setSelectOrder(order);
    setOpenModal(true);
  };

  // Handle tracker submission for "shipped" status
  const handleTrackerSubmit = async () => {
    if (!trackerId || !selectedOrder) return;

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("status", "shipped");
      formData.append("trackerId", trackerId);
      formData.append("speedpostVia", speedpostVia);
      if (invoiceFile) {
        formData.append("invoice", invoiceFile);
      }

      await axios.put(
        `${BASE_URL}/api/v1/order/${selectedOrder._id}`,
        // `http://localhost:4000/api/v1/order/${selectedOrder._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order._id === selectedOrder._id
            ? {
                ...order,
                status: "shipped",
                trackerId: trackerId,
                speedpostVia: speedpostVia,
              }
            : order
        )
      );

      setTrackerId("");
      setSpeedpostVia("");
      setInvoiceFile(null);
      setShowTrackerModal(false);
      toast.success("Order status updated successfully");
    } catch (error) {
      console.error("Error updating order status and tracker ID:", error);
      toast.error("Error updating order status and tracker ID");
    } finally {
      setLoading(false);
    }
  };

  // Close modals
  const closeModal = () => {
    setOpenModal(false);
    setSelectOrder(null);
  };

  const closeTrackerModal = () => {
    setShowTrackerModal(false);
    setTrackerId("");
    setSpeedpostVia("");
    setInvoiceFile(null);
  };

  // Filter orders based on search term and category filter
  const filteredOrders = orders.filter((order) => {
    const matchesSearchTerm = order.shippingAddress?.fullname
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const matchesCategoryFilter = categoryFilter
      ? order.products.some((product) => product.category === categoryFilter)
      : true;
    return matchesSearchTerm && matchesCategoryFilter;
  });

  // Pagination control
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Get status class for styling
  const getStatusClass = (status) => {
    switch (status) {
      case "pending":
        return "bg-yellow-500 text-white";
      case "dispatch":
        return "bg-blue-500 text-white";
      case "shipped":
        return "bg-orange-500 text-white";
      case "delivered":
        return "bg-green-500 text-white";
      case "cancelled":
        return "bg-red-500 text-white";
      default:
        return "bg-gray-300 text-gray-700";
    }
  };

  return (
    <div className="ml-72 bg-[#090D16] h-screen">
      <ToastContainer />
      <Header />
      <h1 className="text-2xl font-semibold mb-6 px-6 text-white mt-4">
        Order Management
      </h1>
      <div className="flex mb-6 px-6">
        <input
          type="text"
          placeholder="Search by user name"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mr-4 p-2 border border-gray-300 rounded"
        />
        <select
          value={categoryFilter}
          onChange={handleCategoryChange}
          className="p-2 border border-gray-300 rounded bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="">All Categories</option>
          {categories.map((category) => (
            <option key={category._id} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>
      </div>
      <div className="text-white shadow-md rounded-lg p-6 bg-[#090D16]">
        <h2 className="text-lg font-medium mb-4">
          Total Orders: {totalOrders}
        </h2>
        <table className="min-w-full table-auto text-sm">
          <thead>
            <tr className="text-white border-b bg-[#090D16]">
              <th className="text-left py-2 px-2 uppercase font-semibold text-xs text-white">
                Order ID
              </th>
              <th className="text-left py-2 px-2 uppercase font-semibold text-xs text-white">
                User
              </th>
              <th className="text-left py-2 px-2 uppercase font-semibold text-xs text-white">
                Email
              </th>
              <th className="text-left py-2 px-2 uppercase font-semibold text-xs text-white">
                Products
              </th>
              <th className="text-left py-2 px-2 uppercase font-semibold text-xs text-white">
                Total
              </th>
              <th className="text-left py-2 px-2 uppercase font-semibold text-xs text-white">
                Status
              </th>
              <th className="text-left py-2 px-2 uppercase font-semibold text-xs text-white">
                Tracker ID
              </th>
              <th className="text-left py-2 px-2 uppercase font-semibold text-xs text-white">
                Speedpost Via
              </th>

              <th className="text-left py-2 px-2 uppercase font-semibold text-xs text-white">
                Actions
              </th>
              <th className="text-left py-2 px-2 uppercase font-semibold text-xs text-white">
                View
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredOrders.map((order) => (
              <tr key={order._id} className="hover:bg-gray-700">
                <td className="py-2 px-2">{order.orderId}</td>
                <td className="py-2 px-2">
                  {order.shippingAddress?.fullname || "N/A"}
                </td>
                <td className="py-2 px-2">{order.email}</td>
                <td className="py-2 px-2">
                  {order.products.map((product) => (
                    <div key={product._id}>
                      {product.name} ({product.quantity})
                    </div>
                  ))}
                </td>
                <td className="py-2 px-2">₹{order.totalAmount}</td>
                <td className="py-2 px-2">
                  <span
                    className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusClass(
                      order.status
                    )}`}
                  >
                    {order.status}
                  </span>
                </td>
                <td className="py-2 px-2">{order.trackerId || "N/A"}</td>
                <td className="py-2 px-2">{order.speedpostVia || "N/A"}</td>

                <td className="py-2 px-2">
                  <select
                    value={order.status}
                    onChange={(e) =>
                      handleStatusChange(order._id, e.target.value)
                    }
                    className="p-1 rounded bg-black text-xs"
                  >
                    <option value="pending">Pending</option>
                    <option value="dispatch">Dispatch</option>
                    <option value="shipped">Shipped</option>
                    <option value="delivered">Delivered</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </td>
                <td className="py-4 px-6">
                  <button
                    onClick={() => handleViewOrder(order)}
                    className="text-white bg-blue-600 shadow-lg px-3 py-1 rounded"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-4">
          <div className="text-sm">
            Showing {ordersPerPage} of {totalOrders} orders
          </div>
          <div className="flex">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`${
                  currentPage === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                } py-1 px-2 mx-1 rounded text-xs`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>

      {openModal && selectOrder && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg relative">
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 transition"
              onClick={closeModal}
            >
              <MdClose size={24} />
            </button>
            <h2 className="text-2xl font-bold mb-6 text-gray-800">
              Order Details
            </h2>
            <div className="space-y-4">
              <img
                src={selectOrder.products[0].imageUrl}
               alt="ProductImage"
                className="w-32 h-32 object-cover rounded-md shadow-lg"
              />

              <p className="text-gray-600">
                <strong className="text-gray-800">Order ID:</strong>{" "}
                {selectOrder.orderId}
              </p>
              <p className="text-gray-600">
                <strong className="text-gray-800">User:</strong>{" "}
                {selectOrder.shippingAddress?.fullname || "N/A"}
              </p>
              <p className="text-gray-600">
                <strong className="text-gray-800">Email:</strong>{" "}
                {selectOrder.email}
              </p>
              <div>
                <p className="text-gray-600">
                  <strong className="text-gray-800">Products:</strong>
                </p>
                <ul className="list-disc pl-6 space-y-2 text-gray-600">
                  {selectOrder.products.map((product) => (
                    <li key={product._id}>
                      {product.name}{" "}
                      <span className="font-semibold">
                        (Quantity: {product.quantity})
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
              <p className="text-gray-600">
                <strong className="text-gray-800">Total Amount:</strong> ₹
                {selectOrder.totalAmount}
              </p>
              <p className="text-gray-600">
                <strong className="text-gray-800">Status:</strong>{" "}
                {selectOrder.status}
              </p>
              <p className="text-gray-600">
                <strong className="text-gray-800">Tracker ID:</strong>{" "}
                {selectOrder.trackerId || "N/A"}
              </p>
              <p className="text-gray-600">
                <strong className="text-gray-800">Speedpost Via:</strong>{" "}
                {selectOrder.speedpostVia || "N/A"}
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Tracker Modal */}
      {showTrackerModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-md shadow-md w-1/3 relative">
            <button
              className="absolute top-2 right-2 text-gray-700 hover:text-gray-900 transition"
              onClick={closeTrackerModal}
            >
              <MdClose size={24} />
            </button>
            <h2 className="text-xl font-semibold mb-4 text-gray-800">
              Enter Tracker ID
            </h2>
            <input
              type="text"
              placeholder="Tracker ID"
              value={trackerId}
              onChange={(e) => setTrackerId(e.target.value)}
              className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="text"
              placeholder="Speedpost Via"
              value={speedpostVia}
              onChange={(e) => setSpeedpostVia(e.target.value)}
              className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="file"
              onChange={(e) => setInvoiceFile(e.target.files[0])}
              className="w-full mb-4"
            />
            <button
              onClick={handleTrackerSubmit}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition"
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderList;
