import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { POST_USER_lOGIN } from "../Constant/constant";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = {
      length: /.{8,}/,
      special: /[!@#$%^&*(),.?":{}|<>]/,
      number: /[0-9]/,
      uppercase: /[A-Z]/,
      lowercase: /[a-z]/,
    };

    if (!email) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      errors.email = "Email is invalid";
    }

    if (!password) {
      errors.password = "Password is required";
    } else {
      if (!passwordRegex.length.test(password)) {
        errors.password = "Password must be at least 8 characters";
      }
      if (!passwordRegex.special.test(password)) {
        errors.password =
          "Password must contain at least one special character";
      }
      if (!passwordRegex.number.test(password)) {
        errors.password = "Password must contain at least one number";
      }
      if (!passwordRegex.uppercase.test(password)) {
        errors.password = "Password must contain at least one uppercase letter";
      }
      if (!passwordRegex.lowercase.test(password)) {
        errors.password = "Password must contain at least one lowercase letter";
      }
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!validate()) {
      return;
    }

    try {
      const response = await fetch(POST_USER_lOGIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error("Login failed");
      }

      const data = await response.json();
      const token = data.token;

      localStorage.setItem("token", token);
      toast.success("Login successful!");

      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } catch (error) {
      console.error("Login error:", error);

      toast.error("Login failed. Please try again.");
    }
  };

  return (
    <>
      <main className="w-full mt-12 sm:pt-20 sm:mt-0">
        <div className="flex flex-col sm:flex-row sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white shadow-lg">
          <div className="flex flex-col items-center justify-center bg-gray-600 text-white p-8 w-full sm:w-1/3">
            <h2 className="text-2xl font-semibold mb-4 text-center">
              Are you an existing customer?
            </h2>
            <p className="mb-6 text-center">
              Log in with your email & password to get started
            </p>
            <img
              src="https://www.tailwindtap.com/assets/components/form/userlogin/login_tailwindtap.jpg"
              alt="Signup Illustration"
              className="w-full h-32"
            />
          </div>
          <div className="flex-1 overflow-hidden">
            <div className="text-center py-10 px-4 sm:px-14">
              <form onSubmit={handleLogin}>
                <div className="flex flex-col w-full gap-4">
                  <TextField
                    fullWidth
                    id="email"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                  <div className="relative">
                    <TextField
                      fullWidth
                      id="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      error={!!errors.password}
                      helperText={errors.password}
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <FaEyeSlash className="text-gray-500" />
                      ) : (
                        <FaEye className="text-gray-500" />
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2.5 mt-2 mb-32">
                    <button
                      type="submit"
                      className="text-white py-3 w-full bg-gray-600 shadow hover:shadow-lg rounded-sm font-medium"
                    >
                      Login
                    </button>
                    <Link
                      to="/password/forgot"
                      className="hover:bg-gray-50 text-primary-blue text-center py-3 w-full shadow border rounded-sm font-medium"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
              </form>

              <Link
                to="/signup"
                className="hover:bg-gray-50 text-blue-500 text-center py-3 px-5 w-full shadow-md border rounded-lg font-medium mt-4"
              >
                Don&apos;t have any account yet?
                <span className="text-blue-500">Sign Up</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </>
  );
};

export default Login;
