import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import Header from './Header';
import { BASE_URL, GET_CATEGORIES, POST_CATEGORIES } from './Constant/constant';

const AddCategory = () => {
  const [category, setCategory] = useState({
    name: '',
    description: '',
    image: null,
  });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); 

  const MAX_IMAGE_SIZE = 2 * 1024 * 1024;

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(GET_CATEGORIES);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      toast.error('Error fetching categories');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    // Validate image size
    if (file.size > MAX_IMAGE_SIZE) {
      Swal.fire('Image size should be less than 2MB');
      return;
    }

    
    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!validImageTypes.includes(file.type)) {
      Swal.fire('Please upload a valid image format (JPEG, JPG, or PNG)');
      return;
    }

 
    setImagePreview(URL.createObjectURL(file));
    setCategory({ ...category, image: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (!isValid) {
      return;
    }

    const confirmText = editingCategoryId ? 'Do you want to update this category?' : 'Do you want to add this category?';
    Swal.fire({
      title: 'Are you sure?',
      text: confirmText,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: editingCategoryId ? 'Update' : 'Add',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        const formData = new FormData();
        formData.append('name', category.name);
        formData.append('description', category.description);
        if (category.image) {
          formData.append('image', category.image);
        }

        try {
          let response;
          if (editingCategoryId) {
            response = await axios.put(`${BASE_URL}/api/v1/categories/${editingCategoryId}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            toast.success('Category updated successfully!');
          } else {
            response = await axios.post(POST_CATEGORIES, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            toast.success('Category added successfully!');
          }

          setCategory({
            name: '',
            description: '',
            image: null,
          });
          setImagePreview(null); 
          setEditingCategoryId(null);
          fetchCategories();
        } catch (error) {
          const errorMessage = error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occurred. Please try again.';
          toast.error(errorMessage);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleEdit = (cat) => {
    setCategory({
      name: cat.name,
      description: cat.description,
      image: null,
    });
    setImagePreview(cat.imageUrl); 
    setEditingCategoryId(cat._id);
  };

  const handleDelete = (catId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this category?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${BASE_URL}/api/v1/categories/${catId}`;
          await axios.delete(url);
          toast.success('Category deleted successfully!');
          fetchCategories();
        } catch (error) {
          const errorMessage = error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An error occurred. Please try again.';
          toast.error(errorMessage);
        }
      }
    });
  };

  const validateForm = () => {
  
    if (!category.name.trim()) {
      Swal.fire('Category name is required');
      return false;
    }

  
    if (!category.description.trim()) {
      Swal.fire('Description is required');
      return false;
    }

    if (!editingCategoryId && !category.image) {
      Swal.fire('Image is required');
      return false;
    }

    return true;
  };

  return (
    <>
      <div className="ml-72">
        <Header />
      </div>
      <div className="mx-auto p-8 ml-72 bg-[#090D16]">
        <h2 className="text-3xl font-bold mb-6 text-center text-white">
          {editingCategoryId ? 'Edit Category' : 'Add New Category'}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-white">Category Name</label>
            <input
              type="text"
              name="name"
              value={category.name}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
             
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-white">Description</label>
            <textarea
              name="description"
              value={category.description}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
              rows={4}
              
            ></textarea>
          </div>
          <div>
            <label className="block text-sm font-medium text-white">Upload Image</label>
            <input
              type="file"
              onChange={handleImageUpload}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
              required={!editingCategoryId}
            />
            {imagePreview && (
              <div className="mt-4">
                <p className="text-sm text-white mb-2">Image Preview:</p>
                <img src={imagePreview} alt="Preview" className="w-24 h-24 object-cover rounded-md" />
              </div>
            )}
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="submit"
              className={`bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={loading}
            >
              {loading ? 'Processing...' : editingCategoryId ? 'Update Category' : 'Add Category'}
            </button>
          </div>
        </form>

        <h2 className="text-3xl font-bold my-6 text-center text-white">Categories List</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300 rounded-md shadow-md">
            <thead className="bg-gray-100">
              <tr>
                <th className="py-2 px-4 border-b border-gray-300">Name</th>
                <th className="py-2 px-4 border-b border-gray-300">Description</th>
                <th className="py-2 px-4 border-b border-gray-300">Image</th>
                <th className="py-2 px-4 border-b border-gray-300">Actions</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((cat) => (
                <tr key={cat._id}>
                  <td className="py-2 px-4 border-b border-gray-300">{cat.name}</td>
                  <td className="py-2 px-4 border-b border-gray-300">{cat.description}</td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {cat.imageUrl && (
                      <img
                        src={cat.imageUrl}
                        alt={cat.name}
                        className="w-16 h-16 object-cover rounded-md"
                      />
                    )}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <div className="flex space-x-4">
                      <button
                        onClick={() => handleEdit(cat)}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(cat._id)}
                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default AddCategory;
