






import React, { useEffect, useState } from 'react';
import SalesSummaryChart from './SalesSummaryChart';
import SalesByCategoryChart from './SalesByCategoryChart';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { GET_ORDERS, GET_VISITORS } from './Constant/constant';
const MainContent = () => {
  const today = new Date();
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalVisitors, setTotalVisitors] = useState(0);
  const formattedDate = today.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long', 
    year: 'numeric', 
  });

  useEffect(() => {
    const fetchTotalOrders = async () => {
      try {
        const response = await axios.get(GET_ORDERS);
        setTotalOrders(response.data.totalOrders || 0);
        console.log(response, "response ")
      } catch (error) {
        console.error('Error fetching total orders:', error);
      }
    };

    fetchTotalOrders();
  }, []);

  useEffect(() => {
    const fetchTotalVisitors = async () => {
      try {
        const response = await axios.get(GET_VISITORS);
        setTotalVisitors(response.data.totalVisitor || 0);
        console.log(response, "response ")
      } catch (error) {
        console.error('Error fetching total visitors:', error);
      }
    };

    fetchTotalVisitors();
  }, []);

  return (
    <main className="flex-1 p-4 bg-[#090D16]">
      <div className="mb-4">
        <h2 className="text-xl font-bold text-white">Today, {formattedDate}</h2>
      </div>
      <div className="grid grid-cols-3 gap-4 mb-4">
        <div className="p-4 shadow rounded-lg bg-[#162232]">
          <h3 className="text-gray-400">Total Revenue Made <span className="blink">(Coming Soon)</span></h3>
          <p className="text-2xl font-bold text-white">$27,450</p>
          <p className="text-green-500">▲ 5.2% vs Last Month</p>
        </div>
        <Link to="/orders">
        <div className="p-4 shadow rounded-lg bg-[#162232]">
          <h3 className="text-gray-400">Total Orders</h3>
          <p className="text-2xl font-bold text-white">{totalOrders}</p>
          <p className="text-green-500">▲ 5.2% vs Last Month</p>
        </div>
        </Link>
        <Link to='/totalvisitor'>
        <div className="p-4 shadow rounded-lg bg-[#162232]">
          <h3 className="text-gray-400">Total Visitors</h3>
          <p className="text-2xl font-bold text-white">{totalVisitors}</p>
          <p className="text-red-500">▼ 5.2% vs Last Month</p>
        </div>
        </Link>
      </div>
      <div className="flex flex-row gap-5">
        <div className="shadow rounded-lg p-4 mb-4 w-1/2 h-96 bg-[#162232]">
          <h3 className="text-gray-400">Sales Summary </h3>
          <div className="mt-4">
            <SalesSummaryChart />
          </div>
        </div>
        <div className="shadow rounded-lg p-4 w-1/2 h-96 bg-[#162232]">
          <h3 className="text-gray-400">Sales by Category </h3>
          <div className="mt-4">
            <SalesByCategoryChart />
          </div>
        </div>
      </div>

      <style jsx>{`
        
        

        .blink {
          animation: blink 1.5s step-start infinite;
        }

        @keyframes blink {
          0% { opacity: 1; }
          25% { opacity: 0; }
          50% { opacity: 1; }
          75% { opacity: 0; }
          100% { opacity: 1; }
        }
      `}</style>
    </main>
  );
}

export default MainContent;
