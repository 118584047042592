import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { GET_CATEGORIES, POST_PRODUCT } from "../Constant/constant";

const AddVariableProduct = () => {
  const [product, setProduct] = useState({
    name: "",
    description: "",
    size: "",
    gender: "",
    basePrice: "",
    stock: "",
    discount: "",
    discountType: "",
    category: "",
    images: [], 
  });

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(GET_CATEGORIES);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Error fetching categories");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setProduct({ ...product, images: files });
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setProduct({ ...product, category: value });
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("name", product.name);
      formData.append("description", product.description);
      formData.append("size", product.size);
      formData.append("gender", product.gender);
      formData.append("basePricing", product.basePrice);
      formData.append("stock", product.stock);
      formData.append("discount", product.discount);
      formData.append("discountType", product.discountType);
      formData.append("category", product.category);
      product.images.forEach((image) => {
        formData.append("image", image);
      });

      const response = await axios.post(
        POST_PRODUCT,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Product added:", response.data);
      toast.success("Product added successfully");
      setProduct({
        name: "",
        description: "",
        size: "",
        gender: "",
        basePrice: "",
        stock: "",
        discount: "",
        discountType: "",
        category: "",
        image: [],
      });
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("Failed to add product");
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-8 bg-white rounded-md">
      <h2 className="text-2xl font-bold mb-6 text-center">Add Variable Product</h2>
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Product Name
          </label>
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={handleInputChange}
            className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            name="description"
            value={product.description}
            onChange={handleInputChange}
            className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
          ></textarea>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Size
            </label>
            <input
              type="text"
              name="size"
              value={product.size}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
            />
          </div>
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Gender
            </label>
            <div className="flex space-x-2 mt-3">
              {["Men", "Women"].map((gender) => (
                <button
                  key={gender}
                  type="button"
                  onClick={() => setProduct({ ...product, gender })}
                  className={`px-4 py-2 border rounded-md ${
                    product.gender === gender
                      ? "bg-gray-100 text-white"
                      : "bg-gray-200"
                  } focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent`}
                >
                  {gender}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Base Pricing
            </label>
            <input
              type="text"
              name="basePrice"
              value={product.basePrice}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
            />
          </div>
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Stock
            </label>
            <input
              type="text"
              name="stock"
              value={product.stock}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
            />
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Discount
            </label>
            <input
              type="text"
              name="discount"
              value={product.discount}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
            />
          </div>
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Discount Type
            </label>
            <input
              type="text"
              name="discountType"
              value={product.discountType}
              onChange={handleInputChange}
              className="w-full mt-1 p-2 border rounded-md focus:outline-nonefocus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Product Category
          </label>
          <select
            name="category"
            value={product.category}
            onChange={handleCategoryChange}
            className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-1 bg-gray-100  focus:ring-black focus:border-transparent"
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category._id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Upload Images
          </label>
          <input
            type="file"
            multiple
            onChange={handleImageUpload}
            className="w-full mt-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
          />
        </div>
        <div className="flex justify-end space-x-4">
          
          <button
            onClick={handleSubmit}
            className="bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            Add Product
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddVariableProduct;
