import React, { useState } from "react";
import { FaUserCircle } from "react-icons/fa";

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header className="bg-[#162232] shadow-md p-6 flex items-center justify-between">
      <div className="text-2xl font-bold text-white">Dashboard</div>
      <div className="flex-1 mx-6">
        <input
          type="text"
          placeholder="Search Here..."
          className="px-4 py-2 w-full max-w-md border-none rounded-full bg-white text-gray-700 focus:ring-2 focus:ring-blue-500 outline-none transition-shadow duration-300 shadow-lg"
        />
      </div>
      <div className="relative">
        <button
          onClick={toggleDropdown}
          className="flex items-center space-x-2 focus:outline-none"
        >
          <FaUserCircle className="text-2xl text-white" />
          <span className="text-white">My Profile</span>
        </button>
        {dropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg">
            <div className="p-2">
              <div className="flex items-center space-x-2 p-2 hover:bg-gray-100 cursor-pointer">
                <div>
                  <p className="font-bold">Antono</p>
                  <p className="text-sm text-gray-500">antono@admin.global</p>
                </div>
              </div>
              <hr />
              <ul>
                <li className="p-2 hover:bg-gray-100 cursor-pointer">
                  My Account
                </li>
                <li className="p-2 hover:bg-gray-100 cursor-pointer">
                  Preferences
                </li>
                <li className="p-2 hover:bg-gray-100 cursor-pointer">Help</li>
                <hr />
                <li className="p-2 hover:bg-gray-100 cursor-pointer">
                  Sign Out
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
