














import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const SalesByCategoryChart = () => {
  const data = {
    labels: ['Uniform Item', 'Badge Item', 'Other'],
    datasets: [
      {
        label: 'Sales by Category',
        data: [2253, 2253, 2253, 2253],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true, // Makes the chart responsive to container size
    maintainAspectRatio: false, // Allows customization of width and height
    plugins: {
      legend: {
        position: 'top',
        labels: {
          font: {
            size: 18, // Increase legend font size
          },
        },
      },
      title: {
        display: true,
        text: 'Sales by Category',
        font: {
          size: 24,
        },
        color: 'white',
      },
    },
  };

  const chartContainerStyles = {
    width: '300px',
    height: '300px',
    margin: '0 auto',
  };

  return (
    <div style={chartContainerStyles}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default SalesByCategoryChart;