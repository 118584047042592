// import React from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   useLocation,
// } from "react-router-dom";
// import Sidebar from './components/Sidebar';
// import Dashboard from './components/Dashboard';
// import ProductList from './components/ProductList';
// import OrderList from './components/OrderList';
// import CustomerList from './components/CustomerList';
// import Analytics from './components/Analytics';
// import AddProduct from './components/AddProduct';
// import Login from './components/Layout/Login';
// import Signup from "./components/Layout/Signup";
// import AddCategory from "./components/AddCategory";
// import AddMultipleProduct from "./components/AddMultipleProduct";
// import AddVariableProduct from "./components/Layout/AddVariableProduct";
// import Stock from "./components/Stock";

// const App = () => {
//   const location = useLocation();

//   const isLoginRoute = location.pathname === "/login";
//   const isSignupRoute = location.pathname === "/";

//   return (
//     <>

//       { !isSignupRoute && !isLoginRoute   && <Sidebar />}

//       <Routes>
//            <Route path="/login" exact element={<Login/>} />
//            <Route path="/" exact element={<Signup/>} />

//            <Route path="/dashboard" element={<Dashboard />} />
//            <Route path="/products" element={<ProductList />} />
//            <Route path="/add-product" element={<AddProduct/>} />
//            <Route path="/grouped-product" element={<AddMultipleProduct/>} />
//            <Route path="/variable-product" element={<AddVariableProduct/>} />
//            <Route path="/add-category" element={<AddCategory />} />
//            <Route path="/orders" element={<OrderList />} />
//            <Route path="/customers" element={<CustomerList />} />
//            <Route path="/analytics" element={<Analytics />} />
//            <Route path="/stock" element={<Stock />} />
//       </Routes>
//     </>
//   );
// };

// const AppWrapper = () => (
//   <Router>
//     <App />
//   </Router>
// );

// export default AppWrapper;

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";
import ProductList from "./components/ProductList";
import OrderList from "./components/OrderList";
import CustomerList from "./components/CustomerList";
import Analytics from "./components/Analytics";
import AddProduct from "./components/AddProduct";
import Login from "./components/Layout/Login";
import Signup from "./components/Layout/Signup";
import AddCategory from "./components/AddCategory";
import AddMultipleProduct from "./components/AddMultipleProduct";
import AddVariableProduct from "./components/Layout/AddVariableProduct";
import PrivateRoute from "./components/PrivateRoute";
import Stock from "./components/Stock";
import TotalVisitors from "./components/TotalVisitors";
import AddCarousel from './components/AddCarousel'

const App = () => {
  const location = useLocation();

  const isLoginRoute = location.pathname === "/";
  const isSignupRoute = location.pathname === "/signup";

  return (
    <>
      {!isSignupRoute && !isLoginRoute && <Sidebar />}
      <Routes>
        <Route path="/" exact element={<Login />} />{" "}
        <Route path="/signup" exact element={<Signup />} />
        {/* Protected Routes */}{" "}
        <Route
          path="/dashboard"
          element={<PrivateRoute element={Dashboard} />}
        />
        <Route
          path="/products"
          element={<PrivateRoute element={ProductList} />}
        />
        <Route
          path="/add-product"
          element={<PrivateRoute element={AddProduct} />}
        />
        <Route
          path="/grouped-product"
          element={<PrivateRoute element={AddMultipleProduct} />}
        />
        <Route
          path="/variable-product"
          element={<PrivateRoute element={AddVariableProduct} />}
        />
        <Route
          path="/totalvisitor"
          element={<PrivateRoute element={TotalVisitors} />}
        />
        <Route
          path="/add-category"
          element={<PrivateRoute element={AddCategory} />}
        />
        <Route path="/orders" element={<PrivateRoute element={OrderList} />} />
        <Route
          path="/customers"
          element={<PrivateRoute element={CustomerList} />}
        />
          <Route
          path="/carousel"
          element={<PrivateRoute element={AddCarousel} />}
        />
        <Route
          path="/analytics"
          element={<PrivateRoute element={Analytics} />}
        />
        <Route path="/stock" element={<Stock />} />{" "}
      </Routes>{" "}
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
